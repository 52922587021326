import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetricsService } from '@dougs/core/metrics';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { ecommerceShopSyncConfig } from '@dougs/ecommerce/shared';

@Injectable()
export class EcommerceSynchronizedShopModalComponentService {
  metricsBeforeClosed$: Observable<void> = this.modalRef.beforeClosed$.pipe(
    map((event) => {
      if (event.type === 'close' && event.data) {
        this.metricsService.pushMixpanelEvent('e-commerce Sales Channel Synchronization Success Agreed', {
          'Shop URL': this.data.shopUrl,
        });
      } else {
        this.metricsService.pushMixpanelEvent('e-commerce Sales Channel Synchronization Success modal closed', {
          'Shop URL': this.data.shopUrl,
        });
      }
    }),
  );

  shopTypeLabel: string = ecommerceShopSyncConfig[this.data.shopType || 'shopify'].shopTypeLabel;

  constructor(
    private readonly metricsService: MetricsService,
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: { shopUrl: string; shopType?: string },
  ) {}
}
