import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { EcommerceShopUrlComponent } from '../../components/ecommerce-sales-channels/ecommerce-sales-channel/ecommerce-sales-channel-form/ecommerce-shop-sync-form/ecommerce-shop-url/ecommerce-shop-url.component';
import { EcommerceSynchronizedShopModalComponentService } from '../../services/ecommerce-synchronized-shop-modal.component.service';

@Component({
  selector: 'dougs-ecommerce-synchronized-shop-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    EcommerceShopUrlComponent,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    ModalCloseDirective,
  ],
  providers: [EcommerceSynchronizedShopModalComponentService],
  templateUrl: './ecommerce-synchronized-shop-modal.component.html',
  styleUrls: ['./ecommerce-synchronized-shop-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceSynchronizedShopModalComponent {
  constructor(
    public readonly ecommerceSynchronizedShopModalComponentService: EcommerceSynchronizedShopModalComponentService,
    @Inject(MODAL_DATA) public data: { shopUrl: string; shopType?: string },
  ) {}
}
