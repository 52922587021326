<ng-container *ngIf="emitOnValueChanges$ | async" />

<dougs-form-field [autoWidth]="true" [noMargin]="noMargin">
  <label dougsFormFieldLabel for="shopUrl">
    Adresse de votre boutique
    <i (click)="ecommerceShopUrlComponentService.openHelpModal()" class="fal fa-question-circle"></i>
  </label>
  <input
    [formControl]="ecommerceShopUrlComponentService.control"
    [placeholder]="ecommerceShopUrlComponentService.placeholder$()"
    (blur)="onTouched()"
    dougsFormFieldControl
    type="text"
    id="shopUrl"
  />
  <span
    *ngIf="ecommerceShopUrlComponentService.control.errors && ecommerceShopUrlComponentService.control.touched"
    dougsFormFieldError
  >
    <span *ngIf="ecommerceShopUrlComponentService.control.hasError('urlRequired')"> Ce champ est requis </span>
    <span *ngIf="ecommerceShopUrlComponentService.control.hasError('shopifyUrlError')">
      L'URL doit commencer par "https://", terminer par ".myshopify.com" et ne pas contenir "www."
    </span>
    <span *ngIf="ecommerceShopUrlComponentService.control.hasError('wooCommerceUrlError')">
      L'URL doit commencer par "https://"
    </span>
  </span>
</dougs-form-field>
