import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HelpModalComponent, ModalService } from '@dougs/ds';
import { ecommerceShopSyncConfig, isShopSynchronizable } from '@dougs/ecommerce/shared';
import { shopUrlValidator } from '../validators/shop-url.validator';

@Injectable()
export class EcommerceShopUrlComponentService {
  private readonly _shopType: WritableSignal<string | null> = signal<string | null>(null);

  placeholder$: Signal<string | null> = computed(() => {
    const shopType: string | null = this._shopType();
    return shopType ? ecommerceShopSyncConfig[shopType].urlPlaceholder : '';
  });

  control: FormControl<string | null> = new FormControl<string | null>(null);

  constructor(private readonly modalService: ModalService) {}

  set shopType(shopType: string | null) {
    this._shopType.set(shopType);

    if (shopType && isShopSynchronizable(shopType)) {
      this.control.clearValidators();
      this.control.setValidators(shopUrlValidator(shopType));
      this.control.updateValueAndValidity();
    }
  }

  set isTouched(isTouched: boolean) {
    if (isTouched) {
      this.control.markAsTouched();
    }
  }

  openHelpModal(): void {
    const shopType: string | null = this._shopType();
    if (shopType) {
      this.modalService.open(HelpModalComponent, {
        data: {
          title: 'Adresse de votre boutique',
          body: ecommerceShopSyncConfig[shopType].helpModalBody,
        },
      });
    }
  }
}
