import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const shopUrlValidator = (shopType: string): ValidatorFn => {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const shopUrl: string = formControl.value;

    if (!shopUrl) {
      return { urlRequired: true };
    }

    switch (shopType) {
      case 'shopify':
        if (!/^https:\/\/[a-z0-9-_]+\.myshopify\.com$/i.test(shopUrl)) {
          return { shopifyUrlError: true };
        }
        break;
      case 'wooCommerce':
        if (!shopUrl.startsWith('https://')) {
          return { wooCommerceUrlError: true };
        }
        break;
    }

    return null;
  };
};
