<ng-container *ngIf="ecommerceSynchronizedShopModalComponentService.metricsBeforeClosed$ | async" />

<div dougsModalTitle>
  <h6>Synchronisation de la boutique {{ ecommerceSynchronizedShopModalComponentService.shopTypeLabel }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent>
  <div class="icons mb-16">
    @switch (ecommerceSynchronizedShopModalComponentService.data.shopType) {
      @case ('wooCommerce') {
        <img src="images/marketplaces/wooCommerce.svg" alt="WooCommerce logo" height="48" />
      }
      @default {
        <img src="images/marketplaces/shopify.svg" alt="Shopify logo" height="48" />
      }
    }
    <i class="fal fa-arrows-h color-success"></i>
    <img src="images/logo/dougs-logo-round.svg" alt="Dougs logo" height="48" />
  </div>
  <h5>Votre boutique est synchronisée !</h5>
  <p class="color-primary-500">{{ data.shopUrl }}</p>
</div>

<div dougsModalFooter class="mt-16">
  <dougs-button [dougsModalClose]="true" color="primary"> J'ai compris </dougs-button>
</div>
